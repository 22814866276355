<template>
  <v-container class="mt-3 mx-3 px-0 white fill-height v-align-start font-inter card relative">
    <v-row justify="center">
      <v-col cols="12" sm="12">
        <v-progress-linear color="#0C2FF3" background-color="#F2F4F7" value="70"></v-progress-linear>
      </v-col>
      <v-col cols="12" sm="12" class="flex justify-between pl-6 pr-10">
        <div class="back-btn">
          <router-link :to="`/tests/cases/import/csv`">
            <v-icon>mdi-chevron-left</v-icon> {{ $t('backToImportFromCSV') }}
          </router-link>
        </div>
        <router-link :to="`/tests/cases/import/csv`">
          <v-icon size="20px">mdi-close</v-icon>
        </router-link>
      </v-col>
      <v-col cols="12" sm="12" class="block main-area">
        <h2 class="mb-8">{{ $t('columnMapping') }}</h2>
        <div v-for="(item, index) in importedHeaders" :key="index">
          <div class="flex">
            <h5 class="w-50 mr-5">{{ $t('csvField') }}</h5>
            <h5 class="w-50 ml-4">{{ $t('testFiestaField') }}</h5>
          </div>
          <template>
            <div class="flex">
              <v-text-field :value="item" dense height="38px" filled class="round-8 mr-2" placeholder="Field" disabled></v-text-field>
              <v-icon size="20px" class="mb-5">mdi-arrow-right-thick</v-icon>
              <v-select v-model="selectedItems[index]" dense height="38px" :items="importData" filled class="round-8 ml-2" item-value="value" item-text="title" placeholder="Test placeholder"></v-select>
            </div>
          </template>
        </div>
      </v-col>
      <v-col cols="12" sm="12" class="absolute bottom-0 flex justify-end">
        <v-btn class="text-capitalize text-white px-6 py-2 round-6 mr-6 mb-4" color="#0C2FF3" @click="goToPreview">{{ $t('goToPreview') }}</v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  props: ['data'],
  data(){
    return{
      importData: [
        { title: "Name", value: 'name' },
        { title: "ExternalId", value: 'externalId' },
        { title: "Source", value: 'source' },
        { title: "Priority", value: 'priority' },
        { title: "Tag", value: 'tag' },
      ],
      importedHeaders: [],
      importedData: [],
      selectedItems: [],
      dataArray: []
    }
  },
  created() {
    this.dataArray = this.$route.params.data;
    this.importedHeaders = this.dataArray.headers;
    if (this.dataArray?.data) {
      for (let i = 0; i < this.dataArray.data.length; i++) {
        if (this.dataArray?.data[i][0]) {
          let row = this.dataArray.data[i][0].split(';');
          this.importedData[i] = [];
          for (let j = 0; j < row.length; j++) {
            this.importedData[i].push(row[j])
          }
        }
      }
    }
    if (this.importedHeaders) {
      let headers = this.importedHeaders[0].split(';')
      for (let i = 0; i < headers.length; i++) {
        this.selectedItems.push(headers[i]);
      }
    }
  },
  methods: {
    goToPreview() {
      const mappingData = {
        title: this.selectedItems,
        data: this.importedData
      }
      this.$router.push({
        name: 'ImportPreview',
        params: { data: mappingData }
      })
    },
  },
}
</script>
<style>
h2, h5{
  text-align: left;
}
h2{
  font-size: 24px !important;
  font-weight: 700;
  color: #18181A;
}
h5{
  font-size: 14px !important;
  color: #0C111D;
  font-weight: 500;
}
button {
  font-size: 14px;
}
.v-align-start {
  align-items: flex-start !important;
}
.font-inter{
  font-family: Inter;
}
.card{
  border-radius: 8px;
}
.block{
  display: block;
}
.main-area{
  max-width: 438px;
  margin-left: auto;
  margin-right: auto;
}
.round-8{
  border-radius: 8px;
}
.relative{
  position: relative;
}
.absolute{
  position: absolute;
}
.bottom-0{
  bottom: 0;
}
.justify-end{
  justify-content: flex-end;
}
.justify-between{
  justify-content: space-between;
}
.text-white{
  color: white !important;
}
.round-6{
  border-radius: 6px;
}
.flex{
  display: flex;
}
.w-50{
  width: 50%;
}
.aling-left .v-list{
  text-align: left !important;
}
</style>
